<template>
  <div>
    <b-modal
      id="qr-code"
      ref="modal"
      header-class="headerModal"
      centered
      title=""
      hide-footer
      hide-header
    >
      <qrcode-vue
        :value="selectedMaterialVariant"
        :size="460"
        level="H"
      />
    </b-modal>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  props: ['selectedMaterialVariant'],
}


</script>

<style scoped>
</style>
